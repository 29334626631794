<template>
  <div class="data-processing-agreement">
    <Header></Header>
    <scrollAnalytics></scrollAnalytics>

    <div class="data-processing-agreement__double">

      <div class="data-processing-agreement__sidebar">

        <div class="sticky">

          <router-link to="/" class="data-processing-agreement__to-main cursor-hover">
            <svg width="58" height="57" viewBox="0 0 58 57" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M26.5391 19.3223L15.8516 30.01L26.5487 40.7071" stroke="black" stroke-linecap="round"/>
              <path d="M16.2992 29.973L39.2402 29.9732" stroke="black" stroke-linecap="round"/>
            </svg>
            <span v-html="$t('policy.backBtn')"></span>
          </router-link>

          <div class="data-processing-agreement__page-wrapper">
            <router-link class="cursor-hover" to="/privacy-policy" v-html="$t('policy.title')"></router-link>
            <router-link class="cursor-hover" to="/data-processing-agreement">Data Processing Agreement</router-link>
            <router-link class="cursor-hover" to="/code-of-conduct">Code of Conduct</router-link>
            <router-link class="cursor-hover" to="/term-of-service">Term of Service</router-link>
          </div>

        </div>

        <functionalNavigation :currentLink="link" class="drop-mob"></functionalNavigation>


      </div>

      <div class="data-processing-agreement__wrapper">

        <div class="head-content">
          <div class="data-processing-agreement__title" v-html="$t('data-processing-agreement.title')"></div>
          <div class="data-processing-agreement__date" v-html="$t('data-processing-agreement.date')"></div>
        </div>

        <div class="data-processing-agreement__scroll-wrapper" :style="cssVars">
          <div class="data-processing-agreement__step" v-for="(key, value) in $t('data-processing-agreement.text')">

            <div class="data-processing-agreement__step-title" v-html="key.title"></div>

            <div v-if="key.text" class="data-processing-agreement__description" v-html="key.text"></div>

            <div class="data-processing-agreement__item" v-for="(secondKey, secondValue) in key.list">
              <div v-if="secondKey.level1 !== ''" v-bind:class="[{ bottom: secondKey.level2.length !== 0 }]" class="data-processing-agreement__text level1" v-html="secondKey.level1"></div>
              <div class="data-processing-agreement__text level2" v-if="secondKey.level2.length !== 0" v-for="(thirdKey, thirdValue) in secondKey.level2" v-html="thirdKey"></div>
            </div>

            <div v-if="key.text2" class="data-processing-agreement__description" v-html="key.text2"></div>

          </div>
        </div>

      </div>

    </div>

    <!--    <Footer></Footer>-->
  </div>
</template>

<script>

import Footer from "@/components/NewFooter";
import Header from "@/components/Header";

import functionalNavigation from "@/components/functional-navigation";
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "data-processing-agreement",
  components: {
    Footer,
    Header,
    functionalNavigation
  },

  computed: {
    ...mapGetters([
      'getCurrentLangs',
    ]),
    cssVars() {
      return {
        '--footerHeight': this.footerHeight + 'px',
        '--headHeight': this.headHeight + 'px'
      }
    }
  },
  data() {
    return {

      footerHeight: "",
      headHeight: "",
      link: {
        name: "Data Processing Agreement",
        link: "/data-processing-agreement"
      },
    };
  },
  mounted() {
    document.querySelector('#app').classList.add("policy");

    this.$nextTick(() => {
      this.footerHeight = document.querySelector('.footer').offsetHeight
      this.headHeight = document.querySelector('.head-content').offsetHeight
    })

    this.setCurrentPage("data-processing-agreement");

  },

  methods: {
    ...mapMutations(["setCurrentPage"]),
  },

  beforeRouteLeave(to, from, next) {
    document.querySelector('#app').classList.remove("policy");
    next();
  }
}
</script>

<style scoped lang="scss">
.data-processing-agreement {
  min-height: calc((var(--vh, 1vh) * 100));
  display: flex;
  flex-direction: column;

  .gradient-fixed {
    display: none;

    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;

    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, var(--background-color-primary) 100%);

    height: 70px;

    @media screen and (max-width: 540px) {
      display: block;
    }
  }

  #header {
    display: none !important;
  }

  .head-content {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding-top: 80px;
  }

  &__double {
    display: flex;
    justify-content: center;
    margin-bottom: auto;

    padding: 0 20px 40px 20px;

  }

  &__sidebar {
    max-width: 340px;
    min-width: 340px;
    width: 100px;
    margin-right: 20px;
    padding-top: 80px;
    position: relative;

    .sticky {

      position: sticky;
      top: 40px;
    }
  }

  &__to-main {
    display: flex;
    align-items: center;
    margin-bottom: 47px;
    margin-left: -16px;

    span {
      font-family: 'Montserrat';
      font-size: 16px;
      font-weight: 500;
    }

    @media screen and (min-width: 1024px) {
      &:hover {
        svg {
          path {
            stroke: #29F6D9
          }
        }
      }
    }

    &:active {
      svg {
        path {
          stroke: #29F6D9
        }
      }
    }

    svg {
      path {
        transition: all .2s ease;
        stroke: var(--text-primary-color-case);
      }
    }
  }

  &__wrapper {
    max-width: 730px;
    width: 100%;
    margin-left: 20px;
  }

  &__page-wrapper {
    display: flex;
    flex-direction: column;

    a {
      user-select: none;
      text-align: left;
      color: var(--text-primary-color-case);

      font-family: 'Montserrat';
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 33px;

      &.router-link-active {
        padding-left: 42px;
        position: relative;
        font-weight: 600;

        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;


          width: 33px;
          height: 2px;
          background: #29F6D9;

          margin: auto 0;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__title {
    color: var(--text-primary-color-case);
    display: inline-block;

    text-align: left;
    font-family: "TimesNewRoman-I";
    font-style: italic;

    font-weight: 400;
    font-size: 80px;
    line-height: 80px;

    padding: 0 0 8px 0;
    overflow: hidden;

    &.active {


      @media screen and (max-width: 1024px) {
        font-size: 60px;
        line-height: 60px;
      }

      @media screen and (max-width: 515px) {
        font-size: 42px;
        line-height: 42px;
      }
      @media screen and (max-width: 389px) {
        font-size: 38px;
        line-height: 38px;
      }
      @media screen and (max-width: 360px) {
        font-size: 36px;
        line-height: 36px;
      }
    }

    @media screen and (max-width: 900px) {
      font-size: 60px;
      line-height: 60px;
    }

    @media screen and (max-width: 425px) {
      font-size: 44px;
      line-height: 44px;
    }
  }

  &__date {
    color: var(--case-allegra-color-top);

    text-align: left;

    font-size: 16px;
    line-height: 24px;

    letter-spacing: 0.02em;
    margin-top: 10px;

    @media screen and (max-width: 900px) {
      font-size: 14px;
      line-height: 24px;
    }
  }

  &__scroll-wrapper {

    margin-top: 52px;

    @media screen and (max-width: 900px) {
      margin-top: 30px;
    }

    /* width */
    &::-webkit-scrollbar {
      width: 20px;
      height: 100%;

      @media screen and (max-width: 900px) {
        width: 10px;
      }
      @media screen and (max-width: 425px) {
        width: 5px;
      }
    }


    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #D4DADF;
      padding-right: 4px;
    }
  }

  &__step {
    margin-bottom: 47px;

    @media screen and (max-width: 900px) {
      margin-bottom: 40px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__step-title {
    text-align: left;
    color: var(--text-primary-color-case);
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 20px;

    ::v-deep .reg {
      font-weight: 300;
    }

    @media screen and (max-width: 540px) {
      font-size: 20px;
      margin-bottom: 10px;
    }
  }

  &__text {
    color: var(--text-primary-color-case);
    text-align: left;

    font-weight: 300;
    font-size: 16px;
    line-height: 24px;

    ::v-deep table {
      border: 1px solid var(--text-primary-color-case);

      overflow-x: auto;
      border-collapse: collapse;

      thead {
        td {
          font-weight: 500;
        }
      }

      td {
        padding: 15px 10px;
        vertical-align: top;

        &:first-child {
          font-weight: 500;
        }
      }

      tbody {
        tr {
          td {
            &:last-child {
              text-align: left;
            }
          }
        }
      }

      th, td {
        border: 1px solid var(--text-primary-color-case);
        border-collapse: collapse;
        text-align: center;
      }
    }

    ::v-deep a {
      border-bottom: 1px solid var(--text-primary-color-case);
      padding-bottom: 1px;
      // text-decoration: underline;
    }

    ::v-deep span {
      font-weight: 500;


    }


    &.level1 {
      margin-bottom: 20px;

      overflow-x: auto;

      ::v-deep b {
        font-weight: 500;
      }


      &.bottom {
        margin-bottom: 8px;
      }
    }

    &.level2 {
      margin-bottom: 20px;
      padding-left: 32px;

      ::v-deep .bullet {
        position: relative;

        &:before {
          content: '';
          width: 6px;
          height: 6px;
          background-color: var(--text-primary-color-case);
          border-radius: 50%;
          position: absolute;
          left: -15px;
          top: 7px;
        }
      }


      ::v-deep b {
        font-weight: 500;
      }
    }
  }

  &__description {
    text-align: left;

    font-weight: 300;
    font-size: 20px;
    line-height: 28px;

    color: var(--text-primary-color-case);

    ::v-deep a {
      border-bottom: 1px solid var(--text-primary-color-case);
      padding-bottom: 1px;
    }
  }

  &__drop-list {
    display: none;
  }

  @media screen and (max-width: 920px) {

    &__to-main {
      span {
        font-weight: 600;
      }
    }

    .head-content {
      padding-top: 48px;
    }

    &__sidebar {
      max-width: 100%;
      min-width: 0;
      width: 100%;
      padding-top: 20px;

      .sticky {
        position: relative;
        top: 0;
      }
    }

    &__double {
      flex-direction: column;
    }

    &__scroll-wrapper {
      height: auto;
    }

    &__wrapper {
      max-width: 100%;
      width: 100%;
      margin-left: 0;
    }

    &__drop-list {
      display: flex;
    }

    &__page-wrapper {
      display: none;
    }
  }

  @media screen and (max-width: 425px) {

    &__to-main {
      span {
        font-size: 12px;
      }
    }
  }
}
</style>
